import React from 'react';
import { Link } from '../../routes';
import './style.scss';

const Button = ({ url, text, locale, children, ...otherProps }) => (
  <Link href={`${url}`} locale={locale}>
    <a {...otherProps}>{text || children || null}</a>
  </Link>
);

export default Button;
