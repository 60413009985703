import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from '../../routes';
import Container from '../../components/Container';
import { BASE_URL } from '../../appConfig';

import './style.scss';

class ServiceList extends Component {
  getServiceList = services => {
    const { lang, displayAll } = this.props;

    return services.map((item, index) => {
      if (!displayAll && item.showOnHomepage === 'false') {
        return;
      } else {
        return (
          <div key={index} className="col-md-4 service_col">
            <Link
              href="serviceDetail"
              locale={lang}
              params={{ slug: item.slug }}
            >
              <div className="card">
                {item.headerImageSmall && (
                  <img
                    className="card-img-top h-100"
                    src={`${BASE_URL}${item.headerImageSmall}`}
                    alt={item.title}
                  />
                )}
                <div className="card-body position-absolute w-100">
                  <h6 className="card-title text-center">{item.title}</h6>
                  {/* <p className="card-text">{item.subtitle}</p> */}
                </div>
              </div>
            </Link>
          </div>
        );
      }
    });
  };

  render() {
    const { data, lang, displayAll, className } = this.props;

    return (
      <section id="sectionServices" className="services">
        <Container className="h100">
          <div className="services__content">
            {this.props.showHeader && (
              <h2 className="h2" >
                <FormattedMessage
                  id="services.headline"
                  defaultMessage="Naše služby"
                />
              </h2>
            )}
            <div className="row equal service-list">
              {this.getServiceList(data)}
            </div>
          </div>
        </Container>
      </section>
    );
  }
}

export default ServiceList;
