import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from '../../routes';
import Container from '../../components/Container';
import Button from '../../components/Button';
import { BASE_URL } from '../../appConfig';

import './style.scss';

class ArticleList extends Component {
  filterArticleList = articles =>
    articles.filter(article => article.showOnHomepage === 'true').slice(0, 3);

  renderArticleList = articles => {
    const { lang } = this.props;

    return articles.map((item, index) => (
      <div key={index} className="col-md-4 article_col">
        <Link href="articleDetail" locale={lang} params={{ slug: item.slug }}>
          <div className="card">
            {item.headerImageSmall && (
              <img
                className="card-img-top"
                src={`${BASE_URL}${item.headerImageSmall}`}
                alt={item.title}
              />
            )}
            <div className="card-body">
              <h5 className="card-title">{item.header}</h5>
              <p className="card-text">{item.subtitle}</p>
            </div>
          </div>
        </Link>
      </div>
    ));
  };

  render() {
    const { data, lang, displayAll, className } = this.props;
    const articles = displayAll ? data : this.filterArticleList(data);
    return (
      <section id="sectionArticles" className="articles">
        <Container className="h100">
          <div className="articles__content">
            {this.props.showHeader && (
              <h2 className="h2">
                <FormattedMessage
                  id="articles.headline"
                  defaultMessage="Články"
                />
              </h2>
            )}
            <div className="row equal article-list">
              {this.renderArticleList(articles)}
            </div>
          </div>
        </Container>
      </section>
    );
  }
}

export default ArticleList;
