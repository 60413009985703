import React, { Component } from 'react';
import fetch from 'isomorphic-unfetch';

import { BASE_URL } from '../config';
import { langFromPath } from '../lib/helpers';

import Hero from '../components/Hero';
import ServiceList from '../modules/ServiceList';
import AboutUs from '../modules/AboutUs';
import ArticleList from '../modules/ArticleList';
import ContactUs from '../modules/ContactUs';
import Map from '../components/GoogleMap';

class Index extends Component {
  render() {
    const { hero, services, articles, contact, lang } = this.props;

    if (!hero) {
      return <div>loading...</div>;
    }
    return (
      <div>
        <Hero data={hero} lang={lang} />
        <ServiceList
          data={services}
          lang={lang}
          displayAll={false}
          className="section"
          showHeader={true}
        />
        <AboutUs lang={lang} />
        {lang === 'cs' && (
          <ArticleList
            data={articles}
            lang={lang}
            displayAll={false}
            className="section"
            showHeader={true}
          />
        )}
        <ContactUs data={contact} lang={lang} />
        <Map />
      </div>
    );
  }
}

Index.getInitialProps = async context => {
  const { asPath } = context;
  const lang = langFromPath(asPath);

  let [resHero, resServices, resArticles, resContact] = await Promise.all([
    fetch(`${BASE_URL}/homepage/${lang}`),
    fetch(`${BASE_URL}/service/${lang}`),
    fetch(`${BASE_URL}/article/${lang}`),
    fetch(`${BASE_URL}/contact/${lang}`)
  ]);

  const hero = await resHero.json();
  const articles = await resArticles.json();
  const services = await resServices.json();
  const contact = await resContact.json();

  return {
    hero,
    articles,
    services,
    contact,
    lang
  };
};

export default Index;
