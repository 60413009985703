import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';
const icn_fcb = '/static/app/icn_facebook.svg';

const icn_pointer = '/static/app/icn_pointer.svg';

const Map = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAccHtSPSnJziHtQczqm_BiIYxbTO1NLwI',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `50vh` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={12.5}
    defaultCenter={{ lat: 50.045244, lng: 14.323293 }}
    defaultOptions={{
      styles: [
        {
          stylers: [
            {
              saturation: -100
            },
            {
              gamma: 1
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.place_of_worship',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.place_of_worship',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'water',
          stylers: [
            {
              visibility: 'on'
            },
            {
              saturation: 50
            },
            {
              gamma: 0
            },
            {
              hue: '#50a5d1'
            }
          ]
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#333333'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text',
          stylers: [
            {
              weight: 0.5
            },
            {
              color: '#333333'
            }
          ]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.icon',
          stylers: [
            {
              gamma: 1
            },
            {
              saturation: 50
            }
          ]
        }
      ],
      gestureHandling: 'greedy',
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: false,
      panControl: false,
      zoomControl: false,
      rotateControl: false,
      fullscreenControl: false
    }}
    disableDefaultUI
  >
    <Marker
      icon={{ url: icn_pointer, scaledSize: { width: 50, height: 50 } }}
      position={{ lat: 50.031311, lng: 14.30591 }}
    />
  </GoogleMap>
));

export default Map;
