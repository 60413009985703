import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../components/Button';
import './style.scss';

const icn_vip = '/static/about/icn_onas_vip.svg';
const icn_cena = '/static/about/icn_onas_cena.svg';
const icn_bezbolestne = '/static/about/icn_onas_bezbolestne.svg';

const data = {
  cs: [
    {
      title: 'Kvalita za rozumnou cenu pro každého',
      text:
        'Naším cílem je, aby se lidé zubaře nebáli. Usilujeme o špičkový servis, za rozumnou cenu, který je přístupný každému.',
      icon: icn_cena
    },
    {
      title: 'Každý z Vás je VIP',
      text:
        'Jsme přesvědčeni, že pacient si zaslouží tu nejlepší možnou péči, proto ke každému z Vás přistupujeme jako k VIP. Nasloucháme, radíme, vysvětlujeme, ošetřujeme.',
      icon: icn_vip
    },
    {
      title: 'Pracujeme moderně a bezbolestně',
      text:
        'Poskytujeme Vám špičkové stomatologické ošetření na nejmodernější evropské úrovni. Prioritou pro nás je pohodlí pacienta a bezbolestnost zákroku.',
      icon: icn_bezbolestne
    }
  ],
  en: [
    {
      title: 'Quality at a reasonable price for everybody',
      text:
        'Our goal is that people do not fear the dentist. We are commited to top-notch service which is affordable for everybody.',
      icon: icn_cena
    },
    {
      title: 'Each of you is a VIP',
      text:
        'We are convinced that a patient deserves the best possible care and that is why we approach every single one of you as a VIP. We listen, advise, explain, and treat.',
      icon: icn_vip
    },
    {
      title: 'We use the most up-to-date methods and pain-free',
      text:
        'We provide top-class dental treatment at the most modern European standard. The priority is the patient‘s comfort and pain-free treatment.',
      icon: icn_bezbolestne
    }
  ]
};

class AboutUs extends Component {
  renderUSP = usp => {
    return usp.map((item, index) => {
      return (
        <div className="usp" key={index}>
          <div className="usp__icon">
            <img src={item.icon} alt={item.title} />
          </div>
          <div className="usp__text">
            <p>
              <strong>{item.title}</strong>
            </p>
            <p>{item.text}</p>
          </div>
        </div>
      );
    });
  };

  render() {
    const { lang } = this.props;
    return (
      <section id="sectionAboutUs" className="about section">
        <div>
          <div className="about__content">
            <div className="about__content__img_about" />
            <div className="about__content__usp_list">
              <h3>
                <FormattedMessage
                  id="aboutus.headline"
                  defaultMessage="O nás"
                />
              </h3>
              {this.renderUSP(data[lang])}
              <div className="about__buttons">
                <FormattedMessage
                  id="aboutus.moreInfo"
                  defaultMessage="Zjistěte o nás více"
                >
                  {txt => (
                    <Button
                      url="about"
                      className="btn btn__white"
                      text={txt}
                      locale={lang}
                    />
                  )}
                </FormattedMessage>
                {/* <FormattedMessage id="aboutus.ourTeam" defaultMessage="Náš tým">
                  {txt => (
                    <Button
                      url="ourTeam"
                      className="btn btn__white_border"
                      text={txt}
                      locale={lang}
                    />
                  )}
                </FormattedMessage> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutUs;
