import React, { Component } from 'react';
import './style.scss';
import Button from '../../components/Button';
import { FormattedMessage } from 'react-intl';

class ContactUs extends Component {
  render() {
    const { lang } = this.props;

    return (
      <div className="row flex-column-reverse flex-md-row contact_us">
        <address className="col-auto contact_us__address section-padding">
          <h3 className="h3_headline">
            <FormattedMessage
              id="hpContact.headline"
              defaultMessage="Kontaktujte nás"
            />
          </h3>
          <div>
            <p>
              <span className="address_label">
                <FormattedMessage
                  id="hpContact.hotline"
                  defaultMessage="Rychlý kontakt"
                />
              </span>
              <br />
              <strong>+420 251 625 056</strong>
              <br />
              <strong>+420 774 444 902</strong>
            </p>
          </div>
          <div>
            <p>
              <span className="address_label">
                <FormattedMessage
                  id="hpContact.emailus"
                  defaultMessage="Kontaktujte nás"
                />
              </span>
              <br />
              <strong>
                <a href="emailto:info@alfadent.cz">info@alfadent.cz</a>
              </strong>
            </p>
          </div>
          <div>
            <p>
              <strong>ALFADENT s.r.o.</strong>
              <br />
              Horšovská 390 / 4 <br />
              150 00, Praha 5 - Řeporyje <br />
              Česká republika
            </p>
            <FormattedMessage
              id="hpContact.btnAll"
              defaultMessage="Další kontakty"
            >
              {txt => (
                <Button
                  url="contact"
                  locale={lang}
                  className="btn btn__blue other_contacts flex-start"
                  text={txt}
                />
              )}
            </FormattedMessage>
          </div>
        </address>
        <div className="col-md-6 contact_us__img" />
      </div>
    );
  }
}

export default ContactUs;
