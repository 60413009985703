import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import './style.scss';
import Container from '../Container';
import Button from '../Button';

class Hero extends Component {
  render() {
    const { lang } = this.props;
    const { header, subtitle } = this.props.data;

    return (
      <section id="sectionHero" className="hero">
        <Container className="h100">
          <div className="hero__content">
            <div className="hero__content__headline">
              <h1>{header}</h1>
            </div>
            <div className="hero__content__subtitle">
              <div className="hero__content__subtitle__text">
                <p>{subtitle}</p>
                <div className="hero__content__actions">
                  <FormattedMessage
                    id="hero.button"
                    defaultMessage="Zjistěte o nás více"
                  >
                    {txt => (
                      <Button
                        url="about"
                        className="btn btn__blue flex-start"
                        text={txt}
                        locale={lang}
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage
                    id="hero.tour"
                    defaultMessage="Virtuální prohlídka"
                  >
                    {txt => (
                      <Button
                        url={
                          lang === 'en'
                            ? '/en/virtual-tour-of-the-clinic'
                            : '/cs/virtualni-prohlidka-kliniky'
                        }
                        className="btn btn__blue flex-start btn-tour"
                        text={txt}
                        locale={lang}
                      />
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
}

export default Hero;
