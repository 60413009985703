const routes = (module.exports = require('next-routes-with-locale')({
  locale: 'cs'
}));

routes.add('index', 'en', '/');
routes.add('index', 'cs', '/');
routes.add('services', 'en', '/services');
routes.add('services', 'cs', '/sluzby');
routes.add('serviceDetail', 'en', '/services/:slug');
routes.add('serviceDetail', 'cs', '/sluzby/:slug');
routes.add('articles', 'en', '/articles');
routes.add('articles', 'cs', '/clanky');
routes.add('articleDetail', 'en', '/articles/:slug');
routes.add('articleDetail', 'cs', '/clanky/:slug');
routes.add('information', 'en', '/information');
routes.add('information', 'cs', '/uzitecne-informace');
routes.add('informationDetail', 'en', '/information/:slug');
routes.add('informationDetail', 'cs', '/uzitecne-informace/:slug');
routes.add('contact', 'en', '/contact');
routes.add('contact', 'cs', '/kontakty');
routes.add('pricing', 'en', '/pricing');
routes.add('pricing', 'cs', '/cenik');
routes.add('about', 'en', '/about');
routes.add('about', 'cs', '/oklinice');
routes.add('ourTeam', 'en', '/our-team');
routes.add('ourTeam', 'cs', '/nas-tym');
routes.add('pageDetail', 'en', '/:slug');
routes.add('pageDetail', 'cs', '/:slug');
